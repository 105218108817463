import React from "react"
import Layout from "../components/layout"
import FAQ from "../components/FAQ"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"

class FAQPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="FAQs" />
        <PageHeader title="FAQs" />
        <FAQ />
      </Layout>
    )
  }
}

export default FAQPage
